import React from "react"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"
import Seo from "../components/seo"
import KBPopupForm from "../components/kbPopupForm"
import Search from "../components/search/knowledgeSearch"
import loadable from "@loadable/component"
import pMinDelay from "p-min-delay"
import "./knowledgebase.css"
import { StaticImage } from "gatsby-plugin-image"

const LeftPanel = loadable(() =>
  pMinDelay(import("../components/panel/leftpanel"), 500)
)

const Banner = ({ posts }) => (
  <>
    <div className="hero-banner knowledge-base-banner" key={posts.idx}>
      <div className="hero-overlay relative">
        <StaticImage
          placeholder="none"
          className="wave-img"
          src="https://cdn.sanity.io/images/55lcecww/production/5ef5b5149a6edf0ad2c67cbe3874446d1cee06e2-904x650.png"
          alt="System Integration"
          layout="constrained"
          style={{ height: "fit-content", zIndex: 0 }}
          fetchpriority="low"
        />
      </div>
      <div className="container">
        <div className="hero-banner-content text-center">
          <h1 className="page-title font-weight-bold text-white mt-2">
            Knowledge Base
          </h1>
          <Search posts={posts} />
        </div>
      </div>
    </div>

    <div className="breadcrumbs d-none">
      <div className="container">
        <ul className="list-unstyled">
          <li>
            <Link aria-label="link" to="/knowledge-base/">
              Knowledge Base
            </Link>
          </li>
        </ul>
      </div>
    </div>
  </>
)

class KnowledgeBasePage extends React.Component {
  render() {
    const { data } = this.props
    const posts = data.categoryBase.edges
    const allPosts = data.allSanityKnowledgeBase.edges
    return (
      <Layout
        banner={<Banner posts={allPosts} />}
        location={this.props.location}
        key={posts.idx}
      >
        <Seo
          title="Knowledge Base | Quandary Consulting Group"
          description="Learn how our experts set up, solve problems, and build solutions using Quickbase, Workato, and Pipefy in our extensive knowledge base."
        />
        <div className="page-content">
          <div className="container">
            {/* <KBPopupForm /> */}
            <div className="row">
              <div className="col-md-3">
                <LeftPanel posts={posts} />
              </div>
              <div className="col-md-9">
                <div className="row justify-content-between align-items-stretch h-100">
                  {posts.map(({ node, idx }) => {
                    let subcat = node.subCategory
                    let pages = node.knowledgebasepage
                    return (
                      <>
                        {subcat.length > 0 || pages.length > 0 ? (
                          <div key={node.id} className="col-md-6 mb-4">
                            <div className="knowledge-section h-100">
                              <div className="knowledge-heading">
                                <span
                                  aria-hidden="true"
                                  className={`knowledge-heading-icon colored ${node.icon}`}
                                />
                                <p className="h5 mt-0 font-weight-bold">
                                  <Link
                                    to={`/knowledge-base/${node.slug.current}/`}
                                    data-wpel-link="internal"
                                  >
                                    {node.title}
                                  </Link>
                                </p>
                              </div>
                              <span className="knowledge-post-count">
                                {subcat.length > 0
                                  ? subcat.length
                                  : pages.length > 0
                                  ? pages.length
                                  : 0}
                              </span>
                              <ul className="knowledge-section-list list-unstyled m-0">
                                {subcat.slice(0, 6).map(item => (
                                  <li
                                    key={item.id}
                                    className="knowledge-post-link"
                                  >
                                    <div className="knowledge-subheading">
                                      <span
                                        aria-hidden="true"
                                        className="knowledge-heading-icon fa fa-folder-o colored mt-1 mr-2"
                                      />
                                      <Link
                                        to={`/knowledge-base/${item.slug.current}/`}
                                        data-wpel-link="internal"
                                      >
                                        {item.title}
                                      </Link>
                                    </div>
                                  </li>
                                ))}
                                {pages.slice(0, 6).map(item => (
                                  <li
                                    key={item.id}
                                    className="knowledge-post-link"
                                  >
                                    <div className="knowledge-subheading">
                                      <span
                                        aria-hidden="true"
                                        className="knowledge-heading-icon fa fa-file-text-o mt-1 mr-2"
                                      />
                                      <Link
                                        to={`/knowledge-base/${node.slug.current}/${item.slug.current}/`}
                                        data-wpel-link="internal"
                                      >
                                        {item.title}
                                      </Link>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                              <div className="d-flex justify-content-start mt-4">
                                <Link
                                  to={`/knowledge-base/${node.slug.current}/`}
                                  className="knowledge-viewall"
                                  data-wpel-link="internal"
                                >
                                  View all{" "}
                                  {subcat.length > 0
                                    ? subcat.length
                                    : pages.length > 0
                                    ? pages.length
                                    : 0}{" "}
                                  articles
                                </Link>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default KnowledgeBasePage

export const knowledgebaseQuery = graphql`
  query knowledgeQuery {
    categoryBase: allSanityKnowledgebasecategory(
      sort: { order: ASC, fields: order }
    ) {
      edges {
        node {
          id
          title
          icon
          slug {
            current
          }
          subCategory {
            id
            title
            slug {
              current
            }
            knowledgebasepage {
              id
              title
              slug {
                current
              }
            }
          }
          knowledgebasepage {
            id
            title
            publishedAt
            slug {
              current
            }
          }
        }
      }
    }
    allSanityKnowledgeBase {
      edges {
        node {
          id
          title
          metaDescription
          body
          slug {
            current
          }
          categories {
            ... on SanitySubCategory {
              id
              title
              slug {
                current
              }
            }
            ... on SanityKnowledgebasecategory {
              id
              title
              slug {
                current
              }
            }
          }
        }
      }
    }
  }
`
